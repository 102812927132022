export default {
  REQUEST_INITIATED: "aanvraagOpgestart",
  FETCH_DATA_FAILED: "bronNietBereikt",
  FETCH_DATA_FAILED_EPC: "epcBronnenNietBereikt",
  FETCH_DATA: "dataOphalen",
  FILLING_IN_REQUEST: "aanvraagInvullen",
  REQUEST_PART_1_SUBMITTED: "gegevensEigendomInvullenEPC",
  CALL_EPC_API_FINISHED: "gegevensEpcLabels",
  REQUEST_PART_2_SUBMITTED: "gegevensAanvragerInvullenEPC",
  REQUEST_SUBMITTED: "aanvraagIngediend",
  AUTHENTIC_DATA_STARTED: "authentiekeDataOpgestart",
  COMMUNICATION_TO_REQUESTOR_INITIATED_EPC:
    "manueleCommunicatieVerstuurdNaarDeAanvragerEPC",
  ADDITIONAL_ATTACHMENTS_REQUESTED: "bijkomendeStukkenOpgevraagdEpc",
  ADDITIONAL_ATTACHMENTS_DELIVERED: "bijkomendeStukkenAangeleverdEpc",
  ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP:
    "bijkomendeStukkenOpgevraagdBeroepEpc",
  ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP:
    "bijkomendeStukkenAangeleverdBeroepEpc",
  ADDITIONAL_ATTACHMENTS_CANCELED: "ophalenBijkomendeStukkenGeannuleerdEpc",
  TIMER_ADDITIONAL_ATTACHMENTS: "timerBijkomendeStukkenEpc",
  DECISION_MADE: "beslissingNaarKlant",
  DECISION_LETTER_SENT: "beslissingsbriefVerstuurd",
  DETAIL_DECISION_LETTER_SENT: "detailBeslissingsbriefVerstuurd",
  DECISION_LETTER_SENT_BEROEP: "beroepBeslissingsbriefVerstuurd",
  DECISION_LETTER_SENT_HEROPENING: "heropeningBeslissingsbriefVerstuurd",
  DETAIL_DECISION_LETTER_SENT_BEROEP: "beroepDetailBeslissingsbriefVerstuurd",
  DETAIL_DECISION_LETTER_SENT_HEROPENING:
    "heropeningDetailBeslissingsbriefVerstuurd",
  BEROEP_SUBMITTED: "beroepIngediend",
  DECISION_MADE_BEROEP: "beslissingNaarKlantBeroepEPC",
  DECISION_MADE_HEROPENING: "beslissingNaarKlantHeropeningEPC",
  BEROEP_NOT_VALID: "beroepDefinitiefOngegrondEPC",
  BEROEP_STARTED: "beroepsprocedureOpgestartEPC",
  BEROEP_TEGEN_STILZITTEN_INGEDIEND: "beroepTegenStilzittenIngediendEPC",
  BEROEP_TEGEN_STILZITTEN_LETTER_SENT: "beroepTegenStilzittenVerstuurdEPC",
  DECISION_LETTER_BEROEP_ONGEGROND_TO_CLIENT:
    "beroepOngegrondBeslissingsbriefVerstuurd",
};
