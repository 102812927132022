import moment from "moment";
import { isFunction } from "lodash";

import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";

export function notifications({ notification, values, dossierType }) {
  return notification && isFunction(notification)
    ? [
        notification({
          values,
          dossierType,
        }),
      ]
    : [];
}

export function notificationsMVL(arrayOfNotifications) {
  return arrayOfNotifications[0].notification &&
    isFunction(arrayOfNotifications[0].notification)
    ? arrayOfNotifications.map((obj) => {
        return obj.notification({
          values: obj.values,
          dossierType: obj.dossierType,
        });
      })
    : [];
}

function formatDate(date) {
  return moment(date).format("D MMMM YYYY");
}

// common notifications
export const notificationRequestNotSubmitted = ({ dossierType }) => {
  let message;
  if (dossierType === "epc_labelPremie") {
    message =
      "Gelieve de premieaanvraag te vervolledigen en vervolgens in te dienen. U kunt het concept steeds zelf annuleren door onder 'Te nemen dossieracties' bij 'Vervolledig gegevens EPC' op de knop 'Uitvoeren' te klikken en vervolgens bovenaan 'Annuleer aanvraag' te kiezen.";
  } else {
    message =
      "Gelieve het formulier te vervolledigen en vervolgens in te dienen. U kunt het concept steeds zelf annuleren door onder ‘vervolledig aanvraag’ op ‘uitvoeren’ te klikken en vervolgens bovenaan ‘Annuleer aanvraag’ te kiezen.";
  }
  return {
    id: "REQUEST_INITIATED",
    title: "Aanvraag nog niet ingediend",
    message: message,
    type: notificationTypes.WARNING,
  };
};

export const notificationFetchingDataFailed = () => ({
  id: "FETCHING_DATA_FAILED",
  title: "Gegevens ophalen mislukt",
  message:
    "U kunt uw aanvraag verderzetten door op de knop 'uitvoeren' te klikken onder 'Haal gegevens opnieuw op'. Blijft deze boodschap verschijnen? Sluit dan de aanvraag af en probeer het later opnieuw.",
  type: notificationTypes.WARNING,
});

export const notificationRequestSubmitted = ({
  values: { requestDate, decisionDate, isBeforeJuly2024 },
  dossierType,
}) => {
  let message;

  if (dossierType === "mijn_verbouwlening") {
    message = `We hebben uw aanvraag van Mijn VerbouwLening goed ontvangen op ${formatDate(
      requestDate
    )}. Uw aanvraag zal nu door uw Energiehuis (zie lager) worden onderzocht. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen maar gezien het grote aantal aanvragen zijn er nog vele wachtenden voor u. Mocht u tegen <strong>${formatDate(
      decisionDate
    )}</strong> nog geen antwoord hebben gekregen, kunt u best uw Energiehuis (zie lager) contacteren. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`;
  } else if (dossierType === "mijn_verbouwpremie") {
    if (isBeforeJuly2024) {
      message = `We hebben uw aanvraag van Mijn VerbouwPremie goed ontvangen op ${formatDate(
        requestDate
      )} en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen ons uiterste best om uw aanvraag zo snel mogelijk te behandelen. <strong>Momenteel bedraagt de gemiddelde behandeltermijn 4,5 maanden</strong>. Mocht u na de uiterlijke behandeltermijn van 8 maanden, dat is op <strong>${formatDate(
        decisionDate
      )}</strong> nog geen beslissing ontvangen hebben, kunt u ons via uw dossier hiervan verwittigen. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.
      Indien u nog bijkomende informatie of documenten wenst aan te leveren, gelieve geen nieuwe aanvraag op te starten maar dit in dit dossier te melden via de optionele dossieractie 'Stel een vraag'. Na deze melding zullen wij u de mogelijkheid geven om bijkomende informatie op te laden.`;
    } else {
      message = `We hebben uw aanvraag van Mijn VerbouwPremie goed ontvangen op ${formatDate(
        requestDate
      )} en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen. <strong>Momenteel bedraagt de gemiddelde behandeltermijn 4,5 maanden</strong>. Mocht u na de uiterlijke behandeltermijn van 6 maanden, dat is op <strong>${formatDate(
        decisionDate
      )}</strong>, nog geen beslissing ontvangen hebben, kunt u ons via uw dossier hiervan verwittigen. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.
      Indien u nog bijkomende informatie of documenten wenst aan te leveren, gelieve geen nieuwe aanvraag op te starten maar dit in dit dossier te melden via de optionele dossieractie 'Stel een vraag'. Na deze melding zullen wij u de mogelijkheid geven om bijkomende informatie op te laden.`;
    }
  } else if (dossierType === "epc_labelPremie") {
    message = `We hebben uw aanvraag van Mijn EPC-Labelpremie goed ontvangen op ${formatDate(
      requestDate
    )} en bekijken of uw dossier alle noodzakelijke documenten bevat. Indien u ten laatste op <strong>${formatDate(
      decisionDate
    )}</strong> nog geen antwoord hebt ontvangen, kunt u beroep aantekenen wegens het uitblijven van de beslissing. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`;
  } else {
    message = `We hebben uw aanvraag goed ontvangen en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen maar gezien het grote aantal aanvragen zijn er nog vele wachtenden voor u. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`;
  }

  return {
    id: "BO_TREATMENT_STARTED",
    title: "Aanvraag ingediend",
    message,
    type: notificationTypes.SUCCESS,
  };
};

// notifications mijn_verbouwlening
export const notitificationDossierRejected = () => ({
  id: "DOSSIER_REJECTED",
  title: "Dossier geweigerd",
  message: `Uw energiehuis heeft uw aanvraag voor een Mijn Verbouwlening verwerkt. Helaas kan deze u niet toegekend worden. De redenen hiervoor kan u terugvinden in de beslissingsbrief die u kan raadplegen onder 'documenten en downloads' op dit dossieroverzicht.`,
  type: notificationTypes.ERROR,
});

export const notitificationDossierCancelled = () => ({
  id: "DOSSIER_CANCELLED",
  title: "Aanvraag geannuleerd door de aanvrager",
  message: `Uw energiehuis heeft uw aanvraag geannuleerd op uw vraag.`,
  type: notificationTypes.ERROR,
});

export const notitificationDossierApproved = () => ({
  id: "DOSSIER_APPROVED",
  title: "Aanvraag goedgekeurd",
  message: `Uw energiehuis heeft uw aanvraag voor een Mijn VerbouwLening goedgekeurd.`,
  type: notificationTypes.SUCCESS,
});

export const notitificationDossierApprovedAndWaiting = () => ({
  id: "DOSSIER_APPROVED_WAITING",
  title: "Aanvraag goedgekeurd",
  message: `Uw energiehuis heeft uw aanvraag voor een Mijn VerbouwLening goedgekeurd en wacht op uw ondertekening.`,
  type: notificationTypes.SUCCESS,
});

export const notitificationDossierAchterstal = () => ({
  id: "DOSSIER_ACHTERSTAL",
  title: "Achterstal",
  message: `U heeft 1 of meerdere afbetalingen niet tijdig of volledig uitgevoerd. Regulariseer dit zo spoedig mogelijk. Bij vragen, neem contact op met uw energiehuis.`,
  type: notificationTypes.ERROR,
});

export const notitificationDossierOpgezegd = () => ({
  id: "DOSSIER_OPGEZEGD",
  title: "Uw krediet werd opgezegd",
  message: `Bij vragen kan u contact opnemen met uw energiehuis.`,
  type: notificationTypes.ERROR,
});

export const notitificationDossierterugbetaald = () => ({
  id: "DOSSIER_TERUGBETAALD",
  title: "Uw krediet is volledig terugbetaald",
  message: `Bij vragen kan u contact opnemen met uw energiehuis.`,
  type: notificationTypes.SUCCESS,
});

export const notitificationActiveDossierCancelled = () => ({
  id: "DOSSIER_ACTIVE_CANCELLED",
  title: "Aanvraag geannuleerd",
  message: `Uw aanvraag werd geannuleerd`,
  type: notificationTypes.ERROR,
});

export const notificationRequestModified = ({
  values: { modifiedRequestDate },
}) => ({
  id: "DOSSIER_GEWIJZIGD_AANVRAGER",
  title: "Gewijzigde aanvraag ingediend",
  message: `We hebben uw gewijzigde aanvraag van Mijn VerbouwLening goed ontvangen op ${formatDate(
    modifiedRequestDate
  )}. Uw aanvraag zal nu door uw Energiehuis (zie lager) worden onderzocht. De status van uw aanvraag blijft zolang ongewijzigd.`,
  type: notificationTypes.SUCCESS,
});

export const notitificationDossierDecided = ({ dossierType }) => {
  let message;

  switch (dossierType) {
    case "mijn_verbouwpremie":
      message = `We hebben uw dossier volledig behandeld. Bij 'dossierdetails' kunt u de beslissing van elke aangevraagde categorie raadplegen. Bij 'documenten en downloads' kunt u een detail van de beslissing nalezen.`;
      break;

    case "epc_labelPremie":
      message = `We hebben uw dossier volledig behandeld. Bij 'dossierdetails' kunt u de beslissing raadplegen. Bij 'documenten en downloads' kunt u een detail van de beslissing nalezen.`;
      break;

    default:
      message = `We hebben uw dossier volledig behandeld. Bij 'dossierdetails' kunt u de beslissing raadplegen. Bij 'documenten en downloads' kunt u een detail van de beslissing nalezen.`;
      break;
  }
  return {
    id: "DOSSIER_DECIDED",
    title: "Dossier beslist",
    message,
    type: notificationTypes.SUCCESS,
  };
};

export const notitificationBeroepDecided = () => ({
  id: "BEROEP_DECIDED",
  title: "Beroep beslist",
  message: `We hebben uw beroep volledig behandeld. Bij 'dossierdetails' kunt u de beslissing van uw beroep raadplegen. Bij 'documenten en downloads' kunt u een detail van de beslissing nalezen.`,
  type: notificationTypes.SUCCESS,
});

// the below notification is also used in epc-labelpremie
export const notificationAdditionalAttachmentsDelivered = ({
  values: { additionalAttachmentsDelivered, decisionDate },
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_DELIVERED",
  title: "Gevraagde informatie aangeleverd",
  message: `We hebben de gevraagde informatie goed ontvangen op <strong>${formatDate(
    additionalAttachmentsDelivered
  )}</strong>. We nemen uw dossier opnieuw in behandeling en sturen u uiterlijk op ${formatDate(
    decisionDate
  )} de beslissing over uw dossier. Mocht u tegen dan nog geen antwoord hebben gekregen, kunt u beroep aantekenen wegens het uitblijven van de beslissing.`,
  type: notificationTypes.SUCCESS,
});

export const notificationAdditionalAttachmentsDeliveredBeroep = ({
  values: { additionalAttachmentsDeliveredBeroep },
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP",
  title: "Gevraagde informatie aangeleverd",
  message: `We hebben de gevraagde informatie goed ontvangen op <strong>${formatDate(
    additionalAttachmentsDeliveredBeroep
  )}</strong>. We nemen uw beroep opnieuw in behandeling.`,
  type: notificationTypes.SUCCESS,
});

export const notificationAdditionalAttachmentsDeliveredHeropening = ({
  values: { additionalAttachmentsDelivered },
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_DELIVERED_HEROPENING",
  title: "Gevraagde informatie aangeleverd",
  message: `We hebben de gevraagde informatie goed ontvangen op <strong>${formatDate(
    additionalAttachmentsDelivered
  )}</strong>. We nemen uw dossier opnieuw in behandeling.`,
  type: notificationTypes.SUCCESS,
});

export const notitificationAppealWaitingTooLong = ({ dossierType }) => {
  let message;
  if (dossierType === "epc_labelPremie") {
    message = `We ontvingen uw beroep tegen het stilzitten (het ontbreken van een beslissing) van onze diensten in verband met uw aanvraag voor Mijn EPC-Labelpremie. U ontvangt hiervan een bevestiging via e-mail. We behandelen uw dossier zo snel mogelijk.`;
  } else {
    message = `We ontvingen uw beroep tegen het stilzitten (het ontbreken van een beslissing) van onze diensten in verband met uw aanvraag voor Mijn VerbouwPremie. U ontvangt hiervan een bevestiging via uw communicatie voorkeur. We behandelen uw dossier zo snel mogelijk.`;
  }
  return {
    id: "BEROEP_TEGEN_STILZITTEN_STARTED",
    title: "Beroep tegen stilzitten ingediend",
    message: message,
    type: notificationTypes.SUCCESS,
  };
};

export const notificationCreditDetails = () => {
  let message = ``;
  message = `
      Uw krediet details zijn beschikbaar onder Documenten & Downloads. U kan ze ook hier downloaden : <a id="download-krediet-details-mvl" href="#">Krediet Details</a>.`;

  return {
    id: "CREDIT_DETAILS",
    title: "Krediet details beschikbaar",
    message: message,
    type: notificationTypes.INFO,
  };
};

// the below notification is also used in epc-labelpremie
export const notificationAdditionalAttachmentsNeeded = ({
  values: { additionalAttachmentsRequested },
  dossierType,
}) => {
  let message;
  if (dossierType === "epc_labelPremie") {
    message = `
      Uw dossier kan niet worden afgewerkt. Laad de ontbrekende informatie/bijlage <strong> tegen ${formatDate(
        additionalAttachmentsRequested
      )}</strong> op via de beschikbare dossieractie 'Laad bijkomende stukken op'. 
      Als u niet reageert op onze vraag, handelen we uw dossier af met de informatie waarover we nu beschikken. 
      </br></br>De ontbrekende gegevens vindt u in de volgende brief : <a id="download-bijkomende-stukken-epc" href="#">Bijkomende stukken aan te leveren</a>.`;
  } else {
    message = `
      Uw dossier kan niet worden afgewerkt. Laad de ontbrekende informatie/bijlage <strong> tegen ${formatDate(
        additionalAttachmentsRequested
      )}</strong> op via de beschikbare dossieractie 'Laad bijkomende stukken op'. 
      Als u niet reageert op onze vraag, handelen we uw dossier af met de informatie waarover we nu beschikken. 
      </br></br>Welke gegevens er ontbreken vindt u in de brief die u hier kan openen : <a id="download-bijkomende-stukken" href="#">Bijkomende stukken aan te leveren</a>.`;
  }

  return {
    id: "ADDITIONAL_ATTACHMENTS_NEEDED",
    title: "Dossier onvolledig.",
    message: message,
    type: notificationTypes.WARNING,
  };
};

export const notificationAdditionalAttachmentsNeededBeroep = ({
  values: { additionalAttachmentsRequested },
  dossierType,
}) => {
  let message;
  if (dossierType === "epc_labelPremie") {
    message = `Uw beroep kan niet worden afgewerkt. Bepaalde informatie of bijlagen ontbreken. Laad de ontbrekende informatie/bijlage tegen <strong>${formatDate(
      additionalAttachmentsRequested
    )}</strong> op via de beschikbare dossieractie 'Laad bijkomende stukken op'. Als u niet reageert op onze vraag, handelen we uw beroep af met de informatie waarover we nu beschikken. 
      </br></br>De ontbrekende gegevens vindt u in de volgende brief : <a id="download-bijkomende-stukken-epc" href="#">Bijkomende stukken aan te leveren</a>.`;
  } else {
    message = `Uw beroep kan niet worden afgewerkt. Bepaalde informatie of bijlagen ontbreken. Laad de ontbrekende informatie/bijlage tegen <strong>${formatDate(
      additionalAttachmentsRequested
    )}</strong> op via de beschikbare dossieractie 'Laad bijkomende stukken op'. Als u niet reageert op onze vraag, handelen we uw beroep af met de informatie waarover we nu beschikken. 
      </br></br>Welke gegevens er ontbreken vindt u in de brief die u hier kan openen : <a id="download-bijkomende-stukken" href="#">Bijkomende stukken aan te leveren</a>.`;
  }

  return {
    id: "ADDITIONAL_ATTACHMENTS_NEEDED_BEROEP",
    title: "Dossier onvolledig.",
    message: message,
    type: notificationTypes.WARNING,
  };
};

export const notificationRequestSubmittedMvp = ({
  values: { requestDate, decisionDate },
}) => ({
  id: "BO_TREATMENT_STARTED",
  title: "Aanvraag ingediend",
  message: `We hebben uw aanvraag van Mijn VerbouwPremie goed ontvangen op ${formatDate(
    requestDate
  )} en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen maar gezien het grote aantal aanvragen zijn er nog vele wachtenden voor u. Mocht u tegen <strong>${formatDate(
    decisionDate
  )}</strong> nog geen antwoord hebben gekregen kunt u beroep aantekenen wegens het uitblijven van de beslissing. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`,
  type: notificationTypes.SUCCESS,
});

export const notificationBeroepRequestSubmitted = ({
  values: { appealRequestDate, appealDecisionDate },
  dossierType,
}) => {
  let message;
  if (dossierType === "epc_labelPremie") {
    message = `We hebben uw beroep tegen de beslissing goed ontvangen op ${formatDate(
      appealRequestDate
    )}. We onderzoeken uw beroep en gaan na of het gegrond is. Uiterlijk op <strong>${formatDate(
      appealDecisionDate
    )}</strong>  ontvangt u onze beslissing.`;
  } else {
    message = `We hebben uw beroep tegen de beslissing goed ontvangen op ${formatDate(
      appealRequestDate
    )}. We onderzoeken uw beroep en gaan na of het gegrond is. Uiterlijk op <strong>${formatDate(
      appealDecisionDate
    )}</strong>  ontvangt u onze beslissing.`;
  }

  return {
    id: "FO_BEROEP_STARTED",
    title: "Beroep ingediend",
    message: message,
    type: notificationTypes.SUCCESS,
  };
};

export const notificationToModifyRequestor = ({
  values: { deadlineForModificationRequestor },
}) => ({
  id: "REQUESTOR_CAN_MODIFY_REQUESTOR",
  title: "Wijzigen aanvrager",
  message: `U vraagt een premie aan als eigenaar-bewoner. Na controle stellen we vast dat u niet de juiste eigendomsrechten heeft en/of niet gedomicilieerd bent in de premiewoning. Hierdoor behoort u automatisch toe aan de doelgroep ‘niet eigenaar-bewoner’. Bijgevolg zal u dan ook de premie voor deze doelgroep ontvangen. Dit is de laagste premie voor uitsluitend energiebesparende werken.</br></br>
   Als een ander meerderjarig persoon met wie u samenwoont wel beschikt over de juiste eigendomsrechten én gedomicilieerd is in de premiewoning, kan deze persoon een nieuwe premieaanvraag indienen zodat er mogelijk een hogere premie kan worden toegekend. Meer informatie over wie welke premies kan aanvragen en op welke premie elke doelgroep recht heeft, kunt u terugvinden op  <a href="www.mijnverbouwpremie.be">www.mijnverbouwpremie.be</a> en de simulator op deze pagina.</br></br>
   Gelieve ten laatste <strong>${formatDate(
     deadlineForModificationRequestor
   )}</strong> aan te geven via de beschikbare dossieractie 'Wijzigen aanvrager?' op dit dossieroverzicht of u met deze aanvraag wenst door te gaan of dat een ander persoon die eigenaar-bewoner is de premieaanvraag zal indienen.`,
  type: notificationTypes.WARNING,
});
